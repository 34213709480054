import {
  LoginRNRRouteData,
  getLoginRNRRouteData,
} from "~/server/data/login_rnr_route_data";
import { cache, createAsync } from "@solidjs/router";
import {
  Accessor,
  createMemo,
  createSignal,
  For,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { ribbon, ribbonBlack, StarRow } from "~/assets/assets";
import LoginForm from "~/components/login-rnr/login_form";
import { BrandingMinimal } from "~/widgets/branding";
import { rnrEventManager } from "~/data/events";
import { getClientIdFromCookie } from "~/utils/common";
import { AxisHeader } from "~/components/login-rnr/axis_header";
import { Title } from "@solidjs/meta";
import { RNR_PAGE_TITLE } from "~/data/config";

const getLoginRNRRouteData$C = cache(getLoginRNRRouteData, "login");

const howHubbleCodesWork = [
  "Enter the code above & click “Redeem”",
  "You’ll receive coins in wallet. 1 coin = ₹1",
  "Get the gift card of your choice using coins",
];

export default function LoginRNRRoute() {
  const routeData: Accessor<LoginRNRRouteData | undefined> = createAsync(() =>
    getLoginRNRRouteData$C()
  );
  const [isDropdownOpen, setIsDropdownOpen] = createSignal<boolean>(false);

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
  });

  const isClientAxis = createMemo(() => {
    return routeData()?.authMethod === "CODE";
  });

  return (
    <div
      class=""
      classList={{
        "lg:bg-[linear-gradient(180deg,_#FCFBF7_0%,_#F7F4EB_100%)]":
          !isClientAxis(),
      }}
    >
      <Show when={routeData()?.mode === "rnr"}>
        <Title>{RNR_PAGE_TITLE}</Title>
      </Show>
      <Show when={isClientAxis()}>
        <AxisHeader
          isLoggedIn={() => false}
          mode={routeData()?.mode ?? "rnr"}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      </Show>
      <div
        class="md:flex md:h-screen md:justify-center"
        classList={{
          "md:bg-loginRNRCircleLG md:bg-[center_top] md:bg-no-repeat":
            !isClientAxis(),
        }}
      >
        <div class="flex flex-col md:min-h-screen md:w-[375px]">
          <div class="relative mx-0 -mb-6 mt-0 h-[356px] md:min-h-[356px]">
            <Show when={!isClientAxis()}>
              <div class="md:bgImageNone absolute left-0 top-0 h-full w-full bg-loginRNRCircle bg-[center_top] bg-no-repeat "></div>
            </Show>
            <div class="relative z-10 flex h-full w-full flex-col items-center justify-center">
              <Show when={!isClientAxis()}>
                <div class="absolute left-2/4 top-1/4 flex h-[106px] w-[106px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border-[1px] border-[rgba(254,204,145,0.45)] bg-white p-4 [box-shadow:0px_0px_20px_0px_rgba(255,_196,_127,_0.30)]">
                  <img
                    src={routeData()?.clientLogoUrl}
                    class="w-full"
                    alt={""}
                  />
                </div>
              </Show>
              <div class="absolute left-2/4 top-[65%] flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-2">
                <div class="flex items-center justify-center gap-2 ">
                  <StarRow
                    starSizes={[2, 3.5]}
                    fillColor="#E47B00"
                    isClientAxis={isClientAxis}
                  />
                  <p
                    class="font-sacramento text-h1 font-normal leading-[52px] tracking-normal"
                    classList={{
                      "text-axisPink": isClientAxis(),
                      "text-orange": !isClientAxis(),
                    }}
                  >
                    Welcome
                  </p>
                  <StarRow
                    starSizes={[3.5, 2]}
                    fillColor="#E47B00"
                    isClientAxis={isClientAxis}
                  />
                </div>
                <h1 class="text-center text-h3 text-basePrimaryDark">
                  <Switch
                    fallback={
                      <>
                        Redeem coins to shop <br />
                        on 400+ brands
                      </>
                    }
                  >
                    <Match when={isClientAxis()}>
                      Redeem voucher(s)
                      <br />
                      with code
                    </Match>
                  </Switch>
                </h1>
                <div class="mt-2 w-28">
                  <img src={isClientAxis() ? ribbonBlack : ribbon} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="mx-4 rounded-[26px] md:mx-auto md:w-[372px]">
            <div
              class="rounded-[20px] border border-baseTertiaryDark bg-white py-4"
              classList={{
                "border-8 border-baseTertiaryMedium": isClientAxis(),
              }}
            >
              <LoginForm
                clientId={() => routeData()?.clientId!}
                clientSecret={() => routeData()?.clientSecret!}
                authMethod={() => routeData()?.authMethod!}
                mode={() => routeData()?.mode!}
              />
            </div>
          </div>

          <Show when={isClientAxis()}>
            <div class="m-4 mt-8 border-b border-baseTertiaryDark lg:mx-0">
              <div class="flex items-center gap-3">
                <h3 class="text-nowrap text-bold text-basePrimaryDark">
                  How it works?
                </h3>
                <hr class="w-full text-baseTertiaryDark" />
              </div>
              <ol class="my-3 flex flex-col gap-2">
                <For each={howHubbleCodesWork}>
                  {(text, index) => (
                    <li class="items flex gap-2 text-textDark">
                      <div class="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-baseTertiaryMedium text-[10px] font-bold">
                        {index() + 1}
                      </div>{" "}
                      <span class="text-medium">{text}</span>
                    </li>
                  )}
                </For>
              </ol>
            </div>
          </Show>
          <div class="relative bottom-[0] left-2/4 my-3 -translate-x-1/2">
            <div class="flex items-center justify-center">
              <BrandingMinimal styles="text-textNormal" />
            </div>
            <Show when={isClientAxis()}>
              <p class="mt-2 text-nowrap text-center text-smallMedium text-textNormal">
                © 2024 by Gullak Technologies Pvt Ltd
              </p>
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
}
